<template>
<div>
    <van-nav-bar title="账号登录" left-arrow @click-left="$router.push('/user')" class="title" /><br>
    <van-form @submit="onSubmit">
        <van-field v-model="mobile" name="username" placeholder="请输入手机号" :rules="[{ required: true, message: '请输入账号' }]" /><br>
        <van-field v-model="sms" center clearable label="短信验证码" placeholder="请输入短信验证码">
            <template #button>
                <van-button size="small" type="primary">发送验证码</van-button>
            </template>
        </van-field>
        <div style="margin: 16px">
            <van-button block type="info" native-type="submit">登录</van-button>
        </div>
    </van-form>

</div>
</template>

<script>
import {
    login
} from '@/api/user'
import {
    setToken
} from '@/utils/storage'
export default {
    name: 'LoginPage',
    data() {
        return {
            mobile: '',
            sms: ''
        }
    },
    methods: {
        async onSubmit(values) {
            const res = await login(values)
            setToken(res.body.token) // 存token到本地存储
            this.$toast.success('登录成功')
            this.$router.push('/user')
        }
    }
}
</script>

<style lang="less" scoped>
.link {
    display: block;
    margin-top: 25px;
    text-align: center;
    font-size: 14px;
    color: #666;
}
</style>

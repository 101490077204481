<template>
  <div style="background-color:#f6f5f6;width:100%">
    <!-- 标题栏 -->
    <van-nav-bar
      title="发布房源"
      left-arrow
      @click-left="$router.go(-1)"
      class="title"
    />
    <!-- 表单 -->
    <van-form @submit="onSubmit">
      <!-- <van-field style="font-size:15px;" disabled label="用户名" /> -->
      <van-cell-group>
        <van-field readonly style="font-size:15px;color:#21b97a" label="房源信息" />
      </van-cell-group>

      <!-- 小区名称 -->
      <van-field
        readonly
        clickable
        name="community"
        label="小区名称"
        @click="$router.push('/rent/search')"
      >
        <template #extra>
          <span style="margin-right:10px;font-size:14px;color:#888">{{ community ? community : '请输入小区名称 ' }}</span>&nbsp;
          <span style="font-size:18px;color:#888">></span>
        </template>
      </van-field>

      <van-field name="price" ref="price" v-model="form.price" @change="formUpdate('price')" label="租金" placeholder="请输入租金/月">
        <template #extra>￥/月</template>
      </van-field>
      <van-field name="size" ref="size" @change="formUpdate('size')" v-model="form.size" label="建筑面积" placeholder="请输入建筑面积">
        <template #extra>㎡</template>
      </van-field>

      <!-- 户型 -->
      <van-field
        readonly
        clickable
        name="roomType"
        label="户型"
        @click="showPicker1 = true"
      >
        <template #extra>
          <span style="margin-right:10px;font-size:14px;color:#888">{{ value1 ? value1 : '请选择 ' }}</span>&nbsp;
          <span style="font-size:18px;color:#888">></span>
        </template>
      </van-field>
      <van-popup v-model="showPicker1" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns1"
          @confirm="onConfirm1"
          @cancel="showPicker1 = false"
        />
      </van-popup>

      <!-- 所在楼层 -->
      <van-field
        readonly
        clickable
        name="floor"
        label="所在楼层"
        @click="showPicker2 = true"
      >
        <template #extra>
          <span style="margin-right:10px;font-size:14px;color:#888">{{ value2 ? value2 : '请选择 ' }}</span>&nbsp;
          <span style="font-size:18px;color:#888">></span>
        </template>
      </van-field>
      <van-popup v-model="showPicker2" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns2"
          @confirm="onConfirm2"
          @cancel="showPicker2 = false"
        />
      </van-popup>

      <!-- 朝向 -->
      <van-field
        readonly
        clickable
        name="oriented"
        label="朝向"
        @click="showPicker3 = true"
      >
        <template #extra>
          <span style="margin-right:10px;font-size:14px;color:#888">{{ value3 ? value3 : '请选择 ' }}</span>&nbsp;
          <span style="font-size:18px;color:#888">></span>
        </template>
      </van-field>
      <van-popup v-model="showPicker3" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns3"
          @confirm="onConfirm3"
          @cancel="showPicker3 = false"
        />
      </van-popup>

      <!-- 房屋标题 -->
      <van-cell-group>
        <van-field readonly label="房屋标题" />
      </van-cell-group>
      <input class="info" ref="title" @change="formUpdate('title')" type="text" name="title" placeholder="请输入标题（例如：整租小区名 2室 5000元）">

      <!-- 房屋图像 -->
      <van-cell-group style="padding-top:10px">
        <van-field readonly label="房屋图像" />
        <van-uploader ref="addImage" enctype="multipart/form-data" name="houseImg" v-model="fileList" multiple style="margin-top:8px;margin-left:8px" :after-read="afterRead" />
      </van-cell-group>

      <!-- 房屋配置 -->
      <van-cell-group style="padding-top:10px">
        <van-field readonly label="房屋配置" />
      </van-cell-group>

      <div class="group" style="background-color:#fff">
        <div v-for="(item, index) in supporting" :key="index" class="group-item" ref="icon" @click="changeColor(index, item.label)">
          <van-icon size="23" name="chat-o" />
          <p style="">{{ item.label }}</p>
        </div>

      </div>

      <!-- 房屋标题 -->
      <van-cell-group style="margin-top:20px">
        <van-field readonly label="房屋描述" />
        <!-- <input class="info" type="text" placeholder="请输入房屋描述信息"> -->
        <textarea ref="description" @change="formUpdate('description')" name="description" class="textarea-info" id="" cols="30" rows="10" placeholder="请输入房屋描述信息"></textarea>
      </van-cell-group>

      <!-- 按钮 -->
      <div class="button-group">
        <van-button color="" block native-type="submit">取消</van-button>
        <van-button block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>

  </div>
</template>

<script>
import { Dialog } from 'vant'
import { issueHouseCondition } from '@/api/list'
import { getCommunityToken } from '@/utils/storage'
import { issueHouse, uploadImage } from '@/api/user'
export default {
  name: 'AddPage',
  async created () {
    const res = await issueHouseCondition()
    // console.log(res)
    console.log('获取发布房源所需条件成功')
    this.supporting = res.body.supporting
    this.floor = res.body.floor
    this.roomType = res.body.roomType
    this.oriented = res.body.oriented
  },
  computed: {
    community () {
      return this.$route.params.communityName
    },
    // 户型
    columns1 () {
      const newArr = []
      this.roomType.forEach(item => {
        newArr.push(item.label)
      })
      return newArr
    },
    // 楼层
    columns2 () {
      const newArr = []
      this.floor.forEach(item => {
        newArr.push(item.label)
      })
      return newArr
    },
    // 朝向
    columns3 () {
      const newArr = []
      this.oriented.forEach(item => {
        newArr.push(item.label)
      })
      return newArr
    }
  },
  data () {
    return {
      // 传给后端的数据
      form: {
        community: getCommunityToken()
      },
      supporting: [], // 配置
      floor: [], // 楼层
      roomType: [], // 户型
      oriented: [], // 朝向
      color: false,
      supportingSelect: [],
      username: '',
      password: '',
      value1: '',
      value2: '',
      value3: '',
      showPicker1: false,
      showPicker2: false,
      showPicker3: false,
      fileList: [
      ]
    }
  },
  methods: {
    async afterRead (file) {
      // 此时可以自行将文件上传至服务器
      console.log(file)
      const formData = new FormData()
      // console.log(this.$refs.addImage)
      formData.append('file', file.file)
      const res = await uploadImage(formData)
      console.log(res)
      console.log('上传头像成功')
      this.form.houseImg = res.body[0]
    },
    formUpdate (key) {
      this.$nextTick(() => {
        this.form[key] = this.$refs[key].value
        console.log(this.form)
      })
    },
    // 表单提交弹窗
    async onSubmit () {
      const res = await issueHouse(this.form)
      console.log(res)
      console.log('发布房源成功')
      Dialog.confirm({
        title: '提示',
        message: '房源发布成功！',
        confirmButtonText: '继续发布',
        confirmButtonColor: '#108ee9',
        cancelButtonText: '去查看',
        cancelButtonColor: '#000'
      }).then(async function () {
        location.reload()
      }).catch(() => {
        this.$router.push('/list')
      })
    },
    // 户型
    onConfirm1 (value) {
      this.value1 = value
      this.showPicker1 = false
      this.form.roomType = this.roomType.find(item => item.label === value).value
    },
    // 楼层
    onConfirm2 (value) {
      this.value2 = value
      console.log(this.value2)
      this.showPicker2 = false
      // console.log(this.floor.find(item => item.label === value))
      this.form.floor = this.floor.find(item => item.label === value).value
    },
    // 朝向
    onConfirm3 (value) {
      this.value3 = value
      this.showPicker3 = false
      this.form.oriented = this.oriented.find(item => item.label === value).value
    },
    // 房屋配置点击
    changeColor (index, name) {
      // e.stopPropagation()
      // console.log(this.$refs.icon[index].children[1].style.color)
      if (!this.$refs.icon[index].style.color) {
        this.$refs.icon[index].style.color = '#21b97a'
        this.$refs.icon[index].children[1].style.color = '#21b97a'
        this.supportingSelect.push(name)
        // this.form.supporting = this.supportingSelect.join('|')
        // console.log(this.form)
      } else {
        this.$refs.icon[index].style.color = null
        this.$refs.icon[index].children[1].style.color = null
        this.supportingSelect.pop(name)
      }
      this.form.supporting = this.supportingSelect.join('|')
      console.log(this.form)
    }
  }
}
</script>

<style lang="less" scoped>
.changeColor {
  color: red;
}
.button-group {
  display: flex;

}
.textarea-info {
  width: 350px;
  border: none;
  outline: none;
  margin-top: 10px;
  padding-left: 16px;
  font-size: 14px;
}
.textarea-info::placeholder {
  color: #bbbbbb;
}
.info {
  width: 350px;
  height: 44px;
  outline: none;
  border: 0;
  font-size: 14px;
  padding-left: 16px;
}
.info::placeholder {
  color: #bbbbbb;
}
.group {
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  .group-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 20%;
    height: 70px;
    padding: 10px 0;
    &:nth-child(n+6) {
      margin-top: -20px;
    }
    p {
      text-align: center;
      font-size: 14px;
      color: #333;
      margin-top:6px;
    }
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('van-nav-bar',{staticClass:"title",attrs:{"title":"地图找房","left-arrow":""},on:{"click-left":function($event){return _vm.$router.go(-1)}}}),_c('van-loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLoading),expression:"showLoading"}],staticClass:"loading",attrs:{"size":"24px","vertical":""}},[_c('p',[_vm._v("加载中...")])]),_c('baidu-map',{staticClass:"map",attrs:{"center":_vm.city,"zoom":_vm.zoom},on:{"ready":_vm.initMap}},[_c('bm-navigation',{attrs:{"anchor":"BMAP_ANCHOR_BOTTOM_RIGHT"}}),(_vm.count < 3)?_c('div',_vm._l((_vm.list),function(item,index){return _c('bm-label',{key:index,ref:"area1",refInFor:true,staticClass:"label",attrs:{"content":item.label + item.count + '套',"position":{ lng: item.coord.longitude, lat: item.coord.latitude },"labelStyle":{
          width: '70px',
          height: '70px',
          lineHeight: '70px',
          display: 'inline-block',
          position: 'absolute',
          borderRadius: '100%',
          backgroundColor: 'rgba(12, 181, 106, 0.9)',
          color: '#fff',
          textAlign: 'center',
          cursor: 'pointer',
          fontSize: '12px',
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor: 'hsla(0, 0%, 100%, 0.8)',
        }},on:{"click":function($event){return _vm.clickFn(index, item.value)}}})}),1):_c('div',_vm._l((_vm.list),function(item,index){return _c('bm-label',{key:index,ref:"area1",refInFor:true,staticClass:"label",attrs:{"content":item.label + item.count + '套',"position":{ lng: item.coord.longitude, lat: item.coord.latitude },"labelStyle":{
          width: '100px',
          height: '20px',
          lineHeight: '19px',
          paddingLeft: '3px',
          paddingRight: '3px',
          borderRadius: '3px',
          position: 'absolute',
          backgroundColor: 'rgba(12,181,106,.9)',
          color: '#fff',
          textAlign: 'center',
          cursor: 'pointer',
          whiteSpace: 'nowrap',
          borderWidth: 'rgba(0,0,0,0)',
        }},on:{"click":function($event){return _vm.clickFn(index, item.value)}}})}),1),_c('bm-scale',{attrs:{"anchor":"BMAP_ANCHOR_BOTTOM_LEFT"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <van-nav-bar
      title="地图找房"
      left-arrow
      @click-left="$router.go(-1)"
      class="title"
    />
    <van-loading class="loading" v-show="showLoading" size="24px" vertical>
      <p>加载中...</p>
    </van-loading>
    <!-- 地图 -->
    <baidu-map @ready="initMap" class="map" :center="city" :zoom="zoom">
      <!-- 缩放 -->
      <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-navigation>
      <!-- 标签 -->
      <div v-if="count < 3">
        <bm-label
          ref="area1"
          @click="clickFn(index, item.value)"
          v-for="(item, index) in list"
          :key="index"
          :content="item.label + item.count + '套'"
          :position="{ lng: item.coord.longitude, lat: item.coord.latitude }"
          class="label"
          :labelStyle="{
            width: '70px',
            height: '70px',
            lineHeight: '70px',
            display: 'inline-block',
            position: 'absolute',
            borderRadius: '100%',
            backgroundColor: 'rgba(12, 181, 106, 0.9)',
            color: '#fff',
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: '12px',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: 'hsla(0, 0%, 100%, 0.8)',
          }"
        />
      </div>

      <div v-else>
        <bm-label
          ref="area1"
          @click="clickFn(index, item.value)"
          v-for="(item, index) in list"
          :key="index"
          :content="item.label + item.count + '套'"
          :position="{ lng: item.coord.longitude, lat: item.coord.latitude }"
          class="label"
          :labelStyle="{
            width: '100px',
            height: '20px',
            lineHeight: '19px',
            paddingLeft: '3px',
            paddingRight: '3px',
            borderRadius: '3px',
            position: 'absolute',
            backgroundColor: 'rgba(12,181,106,.9)',
            color: '#fff',
            textAlign: 'center',
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            borderWidth: 'rgba(0,0,0,0)',
          }"
        />
      </div>

      <!-- 比例尺 -->
      <bm-scale anchor="BMAP_ANCHOR_BOTTOM_LEFT"></bm-scale>
    </baidu-map>
  </div>
</template>

<script>
// import MyOverlay from '@/components/MyOverlay.vue'
import { getAreaData } from '@/api/list'
import { getCityToken, getCityValueToken } from '@/utils/storage'
export default {
  name: 'MapPage',
  components: {
    // MyOverlay
  },
  data () {
    return {
      active: false,
      showLoading: false,
      city: getCityToken(),
      list: [],
      zoom: 11,
      count: 0,
      BMap: null,
      map: null
    }
  },
  methods: {
    initMap ({ BMap, map }) {
      this.BMap = BMap
      this.map = map
    },
    async clickFn (index, value) {
      // console.log(this.$refs.area1)
      // 前两层
      console.log(this.$refs.map1)
      if (this.count < 3) {
        await this.getData(value)
        if (this.count === 2) {
          this.zoom = 13
        } else if (this.count === 3) {
          this.zoom = 16
        }
      } else {
        console.log('更多房源')
      }
      // this.city = { lng: coord.longitude, lat: coord.latitude }
    },
    async getData (val) {
      // 查询该区域房源数据
      this.showLoading = true // 加载中
      let res = {}
      if (!val) {
        // 第一次页面加载 没有传val，用本地存储中的地区id
        res = await getAreaData(getCityValueToken())
      } else {
        res = await getAreaData(val)
      }
      // console.log(res)
      this.showLoading = false // 加载结束
      this.list = res.body
      console.log(this.list)
      this.count++
      // this.lng = +this.list[0].coord.longitude
      // this.lat = +this.list[0].coord.longitude
      this.city = { lng: +this.list[0].coord.longitude, lat: +this.list[0].coord.latitude }
      // console.log(this.count)
      // this.map.setCenter({ lng: +this.list[0].coord.longitude, lat: +this.list[0].coord.latitude })
      console.log(this.city)
    }
  },
  async created () {
    // 查询该区域房源数据
    this.getData()
  }
}
</script>

<style lang="less" scoped>
.loading {
  box-sizing: border-box;
  position: absolute;
  width: 84px;
  height: 98px;
  background-color: #fff;
  top: 50%;
  left: 50%;
  margin-left: -41px;
  margin-top: -49px;
  background-color: rgba(58,58,58,.9);
  padding: 15px;
  border-radius: 5px;
  z-index: 2;
  color: #fff;
  p {
    color: #fff;
    font-size: 14px;
  }
}
.map {
  width: 100%;
  height: 621px;
}
.window {
  height: 20px;
  line-height: 19px;
  width: 100px;
  padding: 0 3px;
  border-radius: 3px;
  position: absolute;
  background: rgba(12, 181, 106, 0.9);
  cursor: pointer;
  white-space: nowrap;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
// 一级路由
import Login from '@/views/Login'
import Register from '@/views/Register'
import Layout from '@/views/Layout'
import Collect from '@/views/Collect'
import Rent from '@/views/Rent'
import Search from '@/views/Search'
import City from '@/views/City'
import Map from '@/views/Map'
// 二级路由
import User from '@/views/second/User'
// import UserLogin from '@/views/second/UserLogin'
import Home from '@/views/second/Home'
import Batch from '@/views/second/Batch'
import List from '@/views/second/List'
import News from '@/views/second/News'
import Add from '@/views/Add'

import { getToken } from '@/utils/storage'
import { Toast } from 'vant'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: 'home',
    children: [
      { path: '/user', component: User },
      { path: '/batch', component: Batch },
      // { path: '/userlogin', component: UserLogin },
      { path: '/list', component: List },
      { path: '/news', component: News },
      { path: '/home', component: Home }
    ]
  },
  {
    path: '/login', // 登录
    component: Login
  },
  {
    path: '/batch', // 登录
    component: Batch
  },
  {
    path: '/register', // 注册
    component: Register
  },
  {
    path: '/collect', // 我的收藏
    component: Collect
  },
  {
    path: '/rent', // 我的出租
    component: Rent
  },
  {
    path: '/rent/add', // 发布房源
    name: 'Add',
    component: Add
  },
  {
    path: '/city', // 城市列表
    component: City
  },
  {
    path: '/rent/search', // 搜索小区
    name: 'Search',
    component: Search
  },
  {
    path: '/map',
    component: Map
  }
]

const router = new VueRouter({
  routes
})

// const whiteList = ['/login', '/register', '/userlogin'] // 白名单
const whiteList = ['/rent', '/collect'] // 白名单
// 全局前置守卫
router.beforeEach((to, from, next) => {
  const token = getToken()
  if (token) {
    next()
  } else {
    if (whiteList.includes(to.path)) {
      Toast.fail('请先登录~')
      next('/login')
    } else {
      next()
    }
  }

  // if (token) {
  //   next()
  // } else {
  //   if (whiteList.includes(to.path)) {
  //     next()
  //   } else {
  //     Toast.fail('请先登录！')
  //     // 拦截到我的(未登录界面)
  //     next('/userlogin')
  //   }
  // }
})

export default router

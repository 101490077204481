<template>
  <div>
    <van-index-anchor :index="indexItem"/>
    <van-cell @click="changeIndex(item.label, item.value)" v-for="(item, index) in listClassify" :key="index" :title="item.label" />
    <!-- <van-cell-group v-for="(item, index) in listClassify" :key="index">
      <van-cell :title="item.label" />
    </van-cell-group> -->
  </div>
</template>

<script>
import { setCityToken, setCityValueToken } from '@/utils/storage'
export default {
  props: {
    list: Array,
    indexItem: String
  },
  computed: {
    // 城市 list 分类
    listClassify () {
      // 每一项的short缩写 的第一个大写字符和 传过来的数组中的每一项相同 筛选
      return this.list.filter(item => item.short[0].toUpperCase() === this.indexItem[0])
    }
  },
  methods: {
    changeIndex (name, val) {
      console.log(name, val)
      setCityToken(name)
      // delCityValueToken()
      setCityValueToken(val)
      this.$router.push('/home')
    }
  }
}
</script>

<style></style>

<template>
  <div>
    <van-nav-bar
      title="房屋管理"
      left-arrow
      @click-left="$router.go(-1)"
      class="title"
    />
    <!-- 房屋管理列表项 -->
    <template v-if="list.length">
      <collect-items
        v-for="(item, index) in list"
        :key="index"
        :item="item"
      ></collect-items>
    </template>
    <template v-else>
      <div class="container">
        <img src="http://liufusong.top:8080/img/not-found.png" alt="" />
        <p>
          您还没有房源，<router-link class="link" to="/rent/add">去发布房源</router-link>叭~
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import CollectItems from '@/components/CollectItems.vue'
import { getHouseList } from '@/api/list'
export default {
  name: 'CollectPage',
  components: {
    CollectItems
  },
  data () {
    return {
      list: []
    }
  },
  async created () {
    const res = await getHouseList()
    // console.log(res)
    this.list = res.body
    console.log('获取我的出租信息成功')
  }
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 150px;
    margin: 30px 0;
  }
  p {
    font-size: 14px;
    color: #333;
    margin-top: 0;
    .link {
      color: #33be85;
    }
  }
}
</style>

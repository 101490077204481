// 默认导入
import request from '@/utils/request'



export const control = (data) => {
  return request.post('/app/device/control', data)
}

export const batch_control = (data) => {
  return request.post('/app/device/batchControl', data)
}



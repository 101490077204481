// 默认导入
import request from '@/utils/request'
import { getCityValueToken } from '@/utils/storage'



// 封装接口，获取城市列表数据
export const getCityList = (level) => {
  return request.get('/area/city', {
    params: {
      level
    }
  })
}

// 获取热门城市
export const getHotCity = () => {
  return request.get('/area/hot')
}

// 根据城市名称查询该城市信息
export const getCityInfoByName = (name) => {
  return request.get('/area/info', {
    params: {
      name
    }
  })
}

// 获取轮播图
export const getSwiperImage = () => {
  return request.get('/home/swiper')
}

// 租房小组
export const getRentGroup = (area) => {
  return request.get('/home/groups', {
    params: {
      area
    }
  })
}

// 获取收藏列表
export const getCollectList = () => {
  return request.get('/user/favorites')
}

// 获取已发布房源列表
export const getHouseList = () => {
  return request.get('/user/houses')
}

// 小区关键词查询
export const getCommunityByName = (obj) => {
  return request.get('/area/community', {
    params: {
      name: obj.name,
      id: obj.id
    }
  })
}

// 发布房屋所需的条件
export const issueHouseCondition = () => {
  return request.get('/houses/params')
}

// 获取房屋查询条件
export const getQueryCondition = (id) => {
  return request.get('/houses/condition', {
    params: {
      id
    }
  })
}

// 查询该区域房源数据
export const getAreaData = (id) => {
  return request.get('/area/map', {
    params: {
      id
    }
  })
}

// 根据条件查询房屋
export const getHousesByCondition = (obj) => {
  return request.get('/houses', {
    params: {
      cityId: getCityValueToken(),
      ...obj
    }
  })
}

<template>
  <div>
    <div class="My_title__39w3V">
      <div class="My_info__eOYeg">
     
      </div>
    </div>
    <van-button
      v-if="token"
      round
      style="height: 30px;position:relative;left:50%;margin-left:-30px;top:-120px"
      type="info"
      text="退出"
      @click="show"
    />
    <van-button
      v-else
      style="height:30px;position:relative;left:50%;margin-left:-35px;top:-70px"
      type="info"
      text="去登录"
      @click="$router.push('/login')"
    />
    <!-- 弹出遮罩 -->
    <!-- <van-dialog confirmButtonColor="#108ee9" v-model="show" title="提示" message="是否确认退出" confirmButtonText="退出" show-cancel-button>
    </van-dialog> -->


  </div>
</template>

<script>
import { Dialog } from 'vant'
import { delCityToken, delCityValueToken, delToken, getToken } from '@/utils/storage'
import { getUserInfo, logout } from '@/api/user'
export default {
  name: 'UserPage',
  nickname: '',
  async created () {
    console.log('获取用户信息成功')
    if (this.token) {
      this.nickname = res.body.nickname
    }
  },
  computed: {
    token () {
      return ''
    },
    imgSrc () {
      return ''
    }
  },
  data () {
    return {
      nickname: '游客'
    }
  },
  methods: {
    show () {
      Dialog.confirm({
        title: '提示',
        message: '是否确认退出',
        confirmButtonColor: '#108ee9'
      }).then(async function () {
        console.log(res)
        console.log('退出成功')
        location.reload() // 重新加载
      })
    }
  }
}
</script>

<style lang="less" scoped>
.My_title__39w3V {
  min-height: 300px;
  position: relative;
  .My_bg__2j-VX {
    width: 100%;
  }
  .My_info__eOYeg {
    position: absolute;
    background: #fff;
    width: 75%;
    height: 55%;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0 10px 3px #ddd;
    margin: 50px auto 0;
    padding: 0 20px;
    text-align: center;
    font-size: 13px;
    .My_myIcon__3cKIV {
      position: relative;
      transform: translateY(-50%);
      border-radius: 50%;
      width: 70px;
      height: 70px;
      border: 5px solid #f5f5f5;
      display: inline-block;
      box-shadow: 0 2px 2px #bdbdbd;
      .My_avatar__2Fbh7 {
        width: 100%;
        border-radius: 50%;
      }
    }
    .My_user__B6O1D {
      padding-top: 15px;
      .My_name__3U2NB {
        margin-top: -30px;
        margin-bottom: 10px;
      }
      .My_auth__1KSIg {
        margin-bottom: 30px;
        // van-button {
        //   display: inline-block;
        //   border-radius: 30px;
        //   color: #fff;
        //   padding: 2px 15px;
        //   background: #21b97a;
        //   font-size: 12px;
        // }
      }
      .My_edit__3wqlv {
        color: #999;
        font-size: 12px;
        margin-top: 20px;
        .My_arrow__1LOxs {
          transform: rotate(-180deg);
          display: inline-block;
          margin-left: 5px;
          padding-bottom: 1px;
          .My_arrow__1LOxs .icon-arrow {
            font-size: 12px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
.My_ad__1wZZj {
  text-align: center;
  margin-top: 10px;
  img {
    width: 92%;
  }
}
</style>

<template>
  <div>
    <!-- 搜索 -->
    <van-search
      style="width: 100%; height: 50px"
      show-action
      :label="city"
      placeholder="请输入小区或地址"
      background="#21b97a"
    >
      <template #left>
        <van-icon
          @click="$router.go(-1)"
          style="margin-right: 5px"
          name="arrow-left"
          color="#fff"
        />
      </template>
      <!-- 自定义右侧 -->
      <template #action>
        <van-icon name="guide-o" color="#fff" />
      </template>
    </van-search>

    <!-- 下拉栏 -->
    <van-dropdown-menu active-color="#21b97a">
      <!-- 区域 -->
      <van-dropdown-item title="区域" ref="area" @open="openArea">
        <van-picker
          ref="pickerArea"
          show-toolbar
          title="标题"
          :columns="areaList"
        >
          <!-- 自定义顶部 -->
          <template #default>&nbsp;</template>
          <!-- 自定义底部 -->
          <template #columns-bottom>
            <div class="btn-group">
              <van-button type="default">取消</van-button>
              <van-button type="info" @click="confirmArea">确定</van-button>
            </div>
          </template>
        </van-picker>
      </van-dropdown-item>

      <!-- 方式 -->
      <van-dropdown-item title="方式" ref="type" @open="openArea">
        <van-picker
          ref="pickerType"
          show-toolbar
          title="标题"
          :columns="rentType"
        >
          <!-- 自定义顶部 -->
          <template #default>&nbsp;</template>
          <!-- 自定义底部 -->
          <template #columns-bottom>
            <div class="btn-group">
              <van-button type="default">取消</van-button>
              <van-button type="info" @click="confirmType">确定</van-button>
            </div>
          </template>
        </van-picker>
      </van-dropdown-item>

      <!-- 租金 -->
      <van-dropdown-item title="租金" ref="price" @open="openArea">
        <van-picker
          ref="pickerPrice"
          show-toolbar
          title="标题"
          :columns="price"
        >
          <!-- 自定义顶部 -->
          <template #default>&nbsp;</template>
          <!-- 自定义底部 -->
          <template #columns-bottom>
            <div class="btn-group">
              <van-button type="default">取消</van-button>
              <van-button type="info" @click="confirmPrice">确定</van-button>
            </div>
          </template>
        </van-picker>
      </van-dropdown-item>

      <!-- 筛选 -->
      <van-dropdown-item title="筛选" @open="openPop" />
      <van-popup
        v-model="show"
        position="right"
        :style="{ width: '80%', height: '100%' }"
      >
        <van-cell value="户型" />
        <div class="group">
          <div
            @click="selectRoomtype(item.value)"
            :class="{ selected: active }"
            class="select"
            v-for="(item, index) in roomType"
            :key="index"
          >
            {{ item.label }}
          </div>
        </div>
      </van-popup>
    </van-dropdown-menu>

    <!-- 城市列表 -->
    <CollectItems
      v-for="(item, index) in list"
      :key="index"
      :item="item"
    ></CollectItems>
  </div>
</template>

<script>
import CollectItems from '@/components/CollectItems.vue'
import {
  getHouseList,
  getHousesByCondition,
  getQueryCondition
} from '@/api/list'
import { getCityToken, getCityValueToken } from '@/utils/storage'
export default {
  name: 'ListPage',
  components: {
    CollectItems
  },
  methods: {
    selectRoomtype (val) {
      this.active = !this.active
      console.log(val)
      const arr = []
      if (this.active) {
        arr.push(val)
        // this.obj.more += val + ','
      } else {
        arr.pop(val)
      }
      console.log(this.arr)
    },
    // 显示弹出层
    openPop () {
      this.show = true
    },
    async getData () {
      const { body } = await getHousesByCondition(this.obj)
      console.log(body)
      this.list = body.list
      console.log(this.list)
    },
    // 打开区域筛选下拉列表
    async openArea () {},
    // 区域
    confirmArea () {
      // console.log(this.$refs.pickerArea.getValues())
      // console.log(this.$refs.pickerArea.getValues().at(-1).value)
      this.$refs.area.toggle() // 关闭下拉框
      this.obj.area = this.$refs.pickerArea.getValues().at(-1).value
      this.getData()
    },
    // 选择类型
    confirmType () {
      this.$refs.type.toggle() // 关闭下拉框
      // console.log(this.$refs.pickerType.getValues())
      this.obj.rentType = this.$refs.pickerType.getValues()[0].value
      this.getData()
    },
    // 选择租金
    confirmPrice () {
      this.$refs.price.toggle() // 关闭下拉框
      // console.log(this.$refs.pickerPrice.getValues())
      this.obj.price = this.$refs.pickerPrice.getValues()[0].value
      this.getData()
    },
    // label改为text
    changeKey (obj, flag = 0) {
      // if (flag) {
      //   if (!obj.children) obj.children = ''
      // }
      const newObj = JSON.parse(JSON.stringify(obj).replace(/label/g, 'text'))
      if (flag) {
        newObj.children.forEach((item) => {
          if (!item.children) {
            item.children = ['']
          }
        })
      }
      // if (newObj.children) {
      //   if (!newObj.children[0].children) newObj.children[0].children = ''
      //   newObj.children = newObj.children.map(item => this.changeKey(item))
      //   // console.log(obj.children)
      // }
      // console.log(newObj)
      return newObj
      // console.log(newObj)
      // console.log(JSON.parse(JSON.stringify(obj).replace('label', 'text')))
    }
  },
  computed: {
    city () {
      return getCityToken()
    }
  },
  data () {
    return {
      show: false,
      areaList: [], // 筛选区域数据
      rentType: [], // 方式
      price: [], // 租金
      list: [],
      obj: {
        more: ''
      }, // 根据条件查询房子
      roomType: [], // 户型
      active: false
    }
  },
  async created () {
    // 未登录时 少了token
    // 获取已发布房源列表
    const resList = await getHouseList()
    console.log('获取已发布房源列表成功')
    this.list = resList.body

    // 获取筛选数据
    const { body } = await getQueryCondition(getCityValueToken())
    console.log(body)
    console.log('获取筛选数据成功')
    // console.log(this.areaList)
    // 区域
    // console.log(body.area)
    console.log(this.areaList)
    this.areaList.push(this.changeKey(body.area, 1))
    // 地铁
    this.areaList.push(this.changeKey(body.subway, 1))
    console.log(this.areaList)
    // this.areaList.map((item) => {
    //   console.log(item)
    //   return this.changeKey(item)
    // }
    // )
    // console.log(this.areaList)
    // 方式
    // label改为text
    this.rentType = body.rentType.map((item) => this.changeKey(item))
    // console.log(this.rentType)
    // console.log(JSON.parse(JSON.stringify({
    //   label: '一室',
    //   value: 'ROOM|d4a692e4-a177-37fd'
    // }).replace('label', 'text')))

    // 租金
    this.price = body.price.map((item) => this.changeKey(item))
    // console.log(this.price)
    // body.price.forEach(item => {
    //   this.price.push(item.label)
    // })

    // 户型
    this.roomType = body.roomType
  }
}
</script>

<style lang="less" scoped>
.selected {
  border: 1px solid #21b97a;
  color: #21b97a;
  background-color: #defaef;
}
.group {
  width: 225px;
  margin-top: 10px;
  margin-left: 50px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 14px;
  .select {
    display: inline-block;
    height: 32px;
    line-height: 32px;
    width: 70px;
    margin: 0 18px 15px 0px;
    border: 1px solid #ddd;
    border-radius: 3px;
    line-height: 32px;
    text-align: center;
    font-size: 12px;
    color: #888;
  }
}
.btn-group {
  display: flex;
  .van-button {
    &:nth-child(1) {
      flex: 1;
    }
    &:nth-child(2) {
      flex: 2;
    }
  }
}
</style>

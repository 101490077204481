<template>
<div>
    <div class="container" v-show="isShow">
        <div id="reader"></div>
    </div>
    <van-nav-bar title="测试设备" left-arrow />
    <div class="content" v-show="!isShow">
        <div class="title" v-show="!scanBtn">设备号：{{ device_no }}，设备类型：{{ device_type }}</div>
        <template>
            <div class="form" v-show="!scanBtn">
                <van-form @submit="onSubmit">
                    <van-field v-model="mode" name="mode" is-link readonly label="模式" placeholder="请选择模式" @click="show1 = true" />
                    <van-popup v-model="show1" round position="bottom">
                        <van-cascader v-model="cascaderValue" title="请选择模式" :options="options1" @close="show1 = false" @finish="onFinish1" />
                    </van-popup>
                    <van-field v-model="time" name="time" is-link readonly label="时长" placeholder="请选择时长" @click="show2 = true" />
                    <van-popup v-model="show2" round position="bottom">
                        <van-cascader v-model="cascaderValue" title="请选择时长" :options="options2" @close="show2 = false" @finish="onFinish2" />
                    </van-popup>

                    <div style="margin: 16px;">
                        <van-button round block type="info" native-type="submit">使用</van-button>
                        <van-button round block type="warning" @click="back" style="margin-top:10px">返回</van-button>
                    </div>
                </van-form>
            </div>
            <div class="scan" v-show="scanBtn">
                <van-button type="primary" @click="scancode">扫一扫</van-button>
            </div>
        </template>
    </div>
</div>
</template>

<script>
import {
    control
} from '@/api/code'
import {
    Html5Qrcode
} from 'html5-qrcode'
import {
    ref
} from 'vue'
import {
    Toast
} from 'vant';
export default {
    name: 'CollectPage',
    components: {},
    data() {
        return {
            username: '',
            password: '',
            scanBtn: true,
            isShow: false,
            device_no: '123',
            device_type: '测试设备',
            list: [],
            show1: false,
            show2: false,
            mode: '模式一',
            time: '6s',
            deviceNo:'1',
            mode_value: 1,
            time_value: 6,
            cascaderValue: '',
            options1: [{
                    text: '模式一',
                    value: '1',
                },
                {
                    text: '模式二',
                    value: '2',
                },
                {
                    text: '模式三',
                    value: '3',
                },
                {
                    text: '模式四',
                    value: '4',
                },
                {
                    text: '模式五',
                    value: '5',
                },
                {
                    text: '模式六',
                    value: '6',
                },
                {
                    text: '模式七',
                    value: '7',
                },
                {
                    text: '模式八',
                    value: '8',
                },
                {
                    text: '模式九',
                    value: '9',
                },
                {
                    text: '模式十',
                    value: '10',
                },
                {
                    text: '模式十一',
                    value: '11',
                },
                {
                    text: '模式十二',
                    value: '12',
                },
                {
                    text: '模式十三',
                    value: '13',
                },
                {
                    text: '模式十四',
                    value: '14',
                },
                {
                    text: '模式十五',
                    value: '15',
                },
                {
                    text: '模式十六',
                    value: '16',
                },
                {
                    text: '模式十七',
                    value: '17',
                },
                {
                    text: '模式十八',
                    value: '18',
                },
                {
                    text: '停止',
                    value: '0',
                },
            ],
            options2: [{
                    text: '6s',
                    value: '6',
                },
                {
                    text: '1min',
                    value: '60',
                },
                {
                    text: '5min',
                    value: '300',
                },
                {
                    text: '10min',
                    value: '600',
                },
                {
                    text: '30min',
                    value: '1800',
                },
                
            ],
        }
    },
    async created() {

    },
    beforeDestroy() {},
    methods: {
        back(){
          this.scanBtn = true;
        },
        scancode() {
            Html5Qrcode.getCameras()
                .then((devices) => {
                    if (devices && devices.length) {
                        this.isShow = true
                        if (devices.length > 1) {
                            this.cameraId = devices[devices.length - 1].id;
                            this.cameraId = devices[1].id;
                        } else {
                            this.cameraId = devices[0].id;
                        }
                        
                        this.devices = devices;
                        this.start();
                    }
                })
                .catch((err) => {
                    console.log(err); // 获取设备信息失败
                });
        },
        start() {
            const html5QrCode = new Html5Qrcode("reader");
            this.html5QrCode = html5QrCode;
            html5QrCode
                .start(
                    this.cameraId, {
                        fps: 10,
                        qrbox: {
                            width: 250,
                            height: 250
                        },
                    },
                    (decodedText, decodedResult) => {
                        let device_no = decodedText
                        if (device_no.indexOf("http") != -1) {
                            let result = this.getUrlParams(device_no)
                            device_no = result["device_id"]
                        }
                        if (device_no.indexOf("M") == -1) {
                            device_no = "M"+device_no
                        }
                        this.deviceNo = device_no;
                        this.device_no = device_no;
                        this.scanBtn = false;
                        this.isShow = false;
                        this.stop();
                        console.log(decodedText);
                        console.log(decodedResult);
                    },
                    (errorMessage) => {
                        console.log(errorMessage);
                    }
                )
                .catch((err) => {
                    console.log(`Unable to start scanning, error: ${err}`);
                });
        },
        stop() {
            this.html5QrCode.stop().then((ignore) => {
                    console.log("QR Code scanning stopped.");
                })
                .catch((err) => {
                    console.log("Unable to stop scanning.");
                });
        },
        async onSubmit(values) {
            values.deviceNo = this.device_no
            values.mode = this.mode_value
            values.time = this.time_value
            const res = await control(values)
            if (res.code == 200) {
                Toast('启动成功');
            } else {
                Toast('启动失败');
            }
        },
        getUrlParams(url) {
            // 通过 ? 分割获取后面的参数字符串
            let urlStr = url.split('?')[1]
            // 创建空对象存储参数
            let obj = {};
            // 再通过 & 将每一个参数单独分割出来
            let paramsArr = urlStr.split('&')
            for (let i = 0, len = paramsArr.length; i < len; i++) {
                // 再通过 = 将每一个参数分割为 key:value 的形式
                let arr = paramsArr[i].split('=')
                obj[arr[0]] = arr[1];
            }
            return obj
        },
        onFinish1({
            selectedOptions
        }) {
            this.show1 = false;
            this.mode = selectedOptions.map((option) => option.text).join('/');
            this.mode_value = selectedOptions.map((option) => option.value)[0];
        },
        onFinish2({
            selectedOptions
        }) {
            this.show2 = false;
            this.time = selectedOptions.map((option) => option.text).join('/');
            this.time_value = selectedOptions.map((option) => option.value)[0];
        },

    }
}
</script>

<style lang="less" scoped>
.container {
    position: relative;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.48);
    z-index: 999;
}

.content {
    display: flex;
    width: 100%;
    height: 80vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.scan {
    bottom: 0px;
}

.form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.title {
    width: 100%;
    text-align: center;
    line-height: 60px;
}

#reader {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
</style>

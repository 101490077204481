<template>
  <div>
    <van-nav-bar
      title="注册"
      left-arrow
      @click-left="$router.push('/login')"
      class="title"
    /><br>
    <van-form @submit="onSubmit">
      <van-field
        v-model="username"
        name="username"
        placeholder="请输入账号"
        :rules="[{ required: true, message: '请输入账号' }]"
      /><br>
      <van-field
        v-model="password"
        type="password"
        name="password"
        placeholder="请输入密码"
        :rules="[{ required: true, message: '请输入密码' }]"
      />
      <div style="margin: 16px">
        <van-button block type="info" native-type="submit"
          >注册</van-button
        >
      </div>
    </van-form>

  </div>
</template>

<script>
import { register } from '@/api/user'
export default {
  name: 'LoginPage',
  data () {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    async onSubmit (values) {
      register(values)
      this.$toast.success('注册成功')
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="less" scoped>
.link {
  display: block;
  margin-top: 25px;
  text-align: center;
  font-size: 14px;
  color: #666;
}
</style>

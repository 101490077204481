<template>
  <div>
    <!-- 二级路由出口 -->
    <router-view></router-view>

    <van-tabbar route>
      <van-tabbar-item to="/home" icon="scan">扫码使用</van-tabbar-item>
      <van-tabbar-item to="/batch" icon="guide-o">批量开启</van-tabbar-item>
      <van-tabbar-item to="/user" icon="user-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'LayoutPage'
}
</script>

<style></style>

<template>
  <div class="houseItem">
    <div class="left">
      <img :src="imgSrc" alt="">
    </div>
    <div class="right">
      <h3 class="title">{{ item.title }}</h3>
      <div class="desc">{{ item.desc }}</div>
      <div class="taggroup">
        <div :class="arr[index]" v-for="(tag, index) in item.tags" :key="index" class="tags">
          <span>{{ tag }}</span>
        </div>
      </div>
      <div class="price">
        <span>{{ item.price }} <i>元/月</i></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object
  },
  data () {
    return {
      arr: ['blue', 'green', 'purple', 'blue', 'green'],
      imgSrc: 'http://liufusong.top:8080' + this.item.houseImg
    }
  },
  computed: {
    // changeColor () {
    //   const r = Math.floor(Math.random() * 256)
    //   const g = Math.floor(Math.random() * 256)
    //   const b = Math.floor(Math.random() * 256)
    //   return `rgb(${r}, ${g}, ${b})`
    // },
  }
}
</script>

<style lang="less" scoped>
.blue {
  color: #39becd;
  background: #e1f5f8;
}
.green {
  color: #3fc28c;
  background: #e1f5ed;
}
.purple {
  color: #5aabfd;
  background: #e6f2ff;
}
.houseItem {
  display: flex;
  width: 100%;
  height: 120px;
  padding-top: 18px;
  border-bottom: 1px solid #e5e5e5;
  .left {
    width: 106px;
    height: 80px;
    img {
      width: 106px;
      height: 80px;
    }
  }
  .right {
    padding-left: 12px;
    .title {
      font-size: 15px;
      color: #394043;
      margin: 0;
      font-weight: bold;
    }
    .desc {
      font-size: 12px;
      color: #afb2b3;
      margin-top: 2px;
    }
    .taggroup {
      display: flex;
      .tags {
        // display: inline-block;
        // width: 46px;
        height: 20px;
        // color: #39becd;
        margin-top: 2px;
        margin-right: 5px;
        padding: 0 5px;
        // background: #e1f5f8;
        text-align: center;
        span {
          font-size: 12px;
        }
      }
    }
    .price {
      margin-top: 2px;
      span {
        font-size: 18px;
        color: #fa5741;
        font-weight: bold;
        i {
          font-style: normal;
          font-size: 12px;
        }
      }
    }
  }
}
</style>

<template>
<div>
    <van-nav-bar title="测试设备" left-arrow />
    <div class="content">
        <div class="title">车牌号：{{ car }},时间30s，模式一</div>
        <template>
            <div class="form">
                <van-form @submit="onSubmit">
                    <van-field v-model="car" name="car" is-link readonly label="车辆" placeholder="请选择车辆" @click="show = true" />
                    <van-popup v-model="show" round position="bottom">
                        <van-cascader v-model="cascaderValue" title="请选择车辆" :options="options" @close="show = false" @finish="onFinish" />
                    </van-popup>
                    <div style="margin: 16px;">
                        <van-button round block type="info" native-type="submit" :disabled='showLoading'>启动</van-button>
                    </div>
                </van-form>
                <van-loading class="loading" v-show="showLoading" size="24px" vertical>
                    <p>进行中</p>
                </van-loading>
            </div>
        </template>
    </div>
</div>
</template>

<script>
import {
    Dialog
} from 'vant'
import {
    batch_control
} from '@/api/code'
import {
    Html5Qrcode
} from 'html5-qrcode'
import {
    ref
} from 'vue'
import {
    Toast
} from 'vant';
export default {
    name: 'CollectPage',
    components: {},
    data() {
        return {
            car: '所有设备',
            showLoading: false,
            car_id: 0,
            scanBtn: true,
            isShow: false,
            show: false,
            device_no: '123',
            device_type: '测试设备',
            list: [],
            mode: '模式一',
            time: '时长一',
            mode_value: 0,
            time_value: 0,
            cascaderValue: '',
            options: [{
                    text: '所有设备',
                    value: 0,
                }, {
                    text: '浙AYKW618',
                    value: 1,
                },

            ],

        }
    },
    async created() {

    },
    beforeDestroy() {},
    methods: {
        async onSubmit(values) {
            this.showLoading = true
            values.car = this.car_id
            const res = await batch_control(values)
            if (res.code == 200) {
                this.showLoading = false
                Dialog.alert({
                    title: '提示',
                    message: res.msg,
                }).then(() => {
                    // on close
                });
            } else {
                Toast(res.msg);
            }
        },

        onFinish({
            selectedOptions
        }) {
            this.show = false
            this.car = selectedOptions.map((option) => option.text).join('/');
            this.car_id = selectedOptions.map((option) => option.value)[0];

        },

    }
}
</script>

<style lang="less" scoped>
.container {
    position: relative;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.48);
    z-index: 999;
}

.content {
    display: flex;
    width: 100%;
    height: 80vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loading {
    box-sizing: border-box;
    position: absolute;
    width: 84px;
    height: 98px;
    background-color: #fff;
    top: 50%;
    left: 50%;
    margin-left: -41px;
    margin-top: -49px;
    background-color: rgba(58, 58, 58, .9);
    padding: 15px;
    border-radius: 5px;
    z-index: 2;
    color: #fff;

    p {
        color: #fff;
        font-size: 14px;
    }
}

.scan {
    bottom: 0px;
}

.form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.title {
    width: 100%;
    text-align: center;
    line-height: 60px;
}

#reader {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
</style>

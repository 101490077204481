<template>
  <div>
    <van-index-anchor index="热门城市" />
    <van-cell @click="changeIndex(item.label, item.value)" v-for="(item, index) in list" :key="index" :title="item.label" />
  </div>
</template>

<script>
import { getHotCity } from '@/api/list'
import { setCityToken, setCityValueToken } from '@/utils/storage'

export default {
  data () {
    return {
      list: []
    }
  },
  async created () {
    const res = await getHotCity()
    console.log('获取热门城市')
    this.list = res.body
    console.log(this.list)
  },
  methods: {
    changeIndex (name, val) {
      setCityToken(name)
      setCityValueToken(val)
      this.$router.push('/home')
    }
  }
}
</script>

<style>

</style>

<template>
  <div>
    <!-- 导航栏 -->
    <van-nav-bar
      title="城市列表"
      left-arrow
      @click-left="$router.go(-1)"
      class="title"
    />

    <!-- 列表内容 -->
    <van-index-bar highlight-color="#21b97a" :index-list="indexList" :sticky="false">

      <!-- 当前城市 -->
      <van-index-anchor index="当前城市" />
      <van-cell :title="currentCity" />

      <!-- 热门城市 -->
      <hot-city></hot-city>

      <!-- A-Z -->
      <city-item
        v-for="(item, index) in indexNew"
        :key="index"
        :list="list"
        :indexItem="item"
      ></city-item>

    </van-index-bar>

  </div>
</template>

<script>
import CityItem from '@/components/CityItem.vue'
import HotCity from '@/components/HotCity.vue'
import { getCityList } from '@/api/list'
import { getCityToken } from '@/utils/storage'
export default {
  name: 'AddPage',
  components: {
    CityItem,
    HotCity
  },
  computed: {
    // A-Z
    indexNew () {
      return this.indexList.join('').substr(2).split('')
    },
    currentCity () {
      return getCityToken() || '上海'
    }
  },
  data () {
    return {
      list: [],
      level: 1,
      indexList: [
        '#',
        '热',
        'A',
        'B',
        'C',
        'D',
        'F',
        'G',
        'H',
        'J',
        'K',
        'L',
        'M',
        'N',
        'Q',
        'S',
        'T',
        'W',
        'X',
        'Y',
        'Z'
      ]
    }
  },
  async created () {
    const resCity = await getCityList(this.level)
    console.log('获取城市列表')
    this.list = resCity.body
    console.log(resCity)
  }
}
</script>

<style></style>

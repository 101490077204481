import request from '@/utils/request'

// 登录接口
export const login = (data) => {
  return request.post('/user/login', data)
}

// 用户退出登录接口
export const logout = () => {
  return request.post('/user/logout')
}

// 注册接口
export const register = (data) => {
  return request.post('/user/registered', data)
}

// 获取用户信息
export const getUserInfo = () => {
  return request.get('/user')
}

// 房屋图像上传接口
export const uploadImage = (data) => {
  return request.post('/houses/image', data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

// 发布房源
export const issueHouse = (obj) => {
  return request.post('/user/houses', obj)
}

<template>
  <div>
    <!-- <div class="search">
      <input type="text" placeholder="请输入小区或地址">
      <span @click="$router.go(-1)">取消</span>
    </div> -->
    <form action="/">
      <van-search
        background="#f6f5f6"
        v-model="value"
        show-action
        placeholder="请输入小区或地址"
        @input="onSearch"
        @cancel="onCancel"
      />
    </form>
    <!-- 搜索结果 -->
    <van-cell-group v-for="(item, index) in list" :key="index">
      <van-cell @click="selectCommunity(item.communityName, item.community)" :value="item.communityName" />
    </van-cell-group>
  </div>
</template>

<script>
import { getCommunityByName } from '@/api/list'
import { getCityValueToken, setCommunityToken } from '@/utils/storage'
export default {
  name: 'SearchPage',
  data () {
    return {
      value: '',
      list: []
    }
  },
  methods: {
    async onSearch (val) {
      // console.log(val)
      const res = await getCommunityByName({
        name: val,
        id: getCityValueToken()
      })
      console.log(res)
      console.log('获取小区信息成功')
      this.list = res.body
      if (!val) {
        this.list = []
      }
    },
    onCancel () {
      this.$router.go(-1)
    },
    // 选择小区，更新至发布房源页面
    selectCommunity (val, token) {
      console.log(val)
      setCommunityToken(token)
      this.$router.push({
        name: 'Add',
        params: {
          communityName: val
        }
      })
    }
  }
}
</script>

<style></style>

// 登录token
const KEY = 'my-token-vant-mobile'

// 城市token
const CITYKEY = 'my-citytoken-vant-mobile'

const CITYVALUEKEY = 'my-cityvaluetoken-vant-mobile'

// 社区token
const COMMUNITYKEY = 'my-communitytoken-vant-mobile'

// 直接用按需导出，可以导出多个
// 但是按需导出，导入时必须 import { getToken } from '模块名导入'
// 获取
// 登录token
export const getToken = () => {
  return localStorage.getItem(KEY)
}

// 设置
export const setToken = (newToken) => {
  localStorage.setItem(KEY, newToken)
}

// 删除
export const delToken = () => {
  localStorage.removeItem(KEY)
}

// 城市token
export const getCityToken = () => {
  return localStorage.getItem(CITYKEY)
}

// 设置
export const setCityToken = (newToken) => {
  localStorage.setItem(CITYKEY, newToken)
}

// 删除
export const delCityToken = () => {
  localStorage.removeItem(CITYKEY)
}

// 城市value token
export const getCityValueToken = () => {
  return localStorage.getItem(CITYVALUEKEY)
}

// 设置
export const setCityValueToken = (newToken) => {
  localStorage.setItem(CITYVALUEKEY, newToken)
}

// 删除
export const delCityValueToken = () => {
  localStorage.removeItem(CITYVALUEKEY)
}

// 社区token
export const getCommunityToken = () => {
  return localStorage.getItem(COMMUNITYKEY)
}

// 设置
export const setCommunityToken = (newToken) => {
  localStorage.setItem(COMMUNITYKEY, newToken)
}

// 删除
export const delCommunityToken = () => {
  localStorage.removeItem(COMMUNITYKEY)
}

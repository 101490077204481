<template>
  <div>
    <h1>咨询</h1>
  </div>
</template>

<script>
export default {
  name: 'NewsPage'
}
</script>

<style>

</style>

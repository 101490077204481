<template>
  <div>
    <van-nav-bar
      title="收藏列表"
      left-arrow
      @click-left="$router.go(-1)"
      class="title"
    />
    <!-- 收藏列表项 -->
    <collect-items v-for="(item, index) in list" :key="index" :item="item"></collect-items>
  </div>
</template>

<script>
import CollectItems from '@/components/CollectItems.vue'
import { getCollectList } from '@/api/list'
export default {
  name: 'CollectPage',
  components: {
    CollectItems
  },
  data () {
    return {
      list: []
    }
  },
  async created () {
    const res = await getCollectList()
    // console.log(res)
    this.list = res.body
    console.log('获取收藏列表成功')
  }
}
</script>

<style>

</style>

import Vue from 'vue'

import {
  Form,
  Field,
  Button,
  NavBar,
  Toast,
  Tabbar,
  TabbarItem,
  Grid,
  GridItem,
  Overlay,
  Popup,
  Dialog,
  Swipe,
  SwipeItem,
  Lazyload,
  Search,
  Icon,
  Image as VanImage,
  DropdownMenu,
  DropdownItem,
  Picker,
  Popover,
  List,
  Cell,
  CellGroup,
  IndexBar,
  IndexAnchor,
  Loading,
  Uploader,
  Cascader,
  Col, Row
} from 'vant'

Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.use(NavBar)
Vue.use(Toast)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Overlay)
Vue.use(Popup)
Vue.use(Dialog)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Lazyload)
Vue.use(Search)
Vue.use(Icon)
Vue.use(VanImage)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Picker)
Vue.use(Popover)
Vue.use(List)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(IndexBar)
Vue.use(IndexAnchor)
Vue.use(Loading)
Vue.use(Uploader)
Vue.use(Cascader);
Vue.use(Col);
Vue.use(Row);
